/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */


/* Core CSS required for Ionic components to work properly */

@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */

@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* NGX Datatable CSS */

// @import '~assets/styles/ngx-datatable/_index.css';
// @import '~assets/styles/ngx-datatable/_dark.css';
// @import '~assets/styles/ngx-datatable/_material.css';
// @import '~assets/styles/ngx-datatable/_bootstrap.css';
// @import '~assets/styles/ngx-datatable/_icons.css';
@import "../node_modules/@swimlane/ngx-datatable/themes/bootstrap.css";
@import "../node_modules/@swimlane/ngx-datatable/themes/dark.css";
@import "../node_modules/@swimlane/ngx-datatable/themes/material.css";
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';
.header-md::after {
    background-image: none !important;
    border-bottom: 0px solid #ccc !important;
}